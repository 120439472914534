<script lang="ts">
  import type { ICartItem } from '../models/cart-item.model';
  import { S_translations } from '../store/store';
  import Price from './Price.svelte';

  export let itemData: ICartItem;
</script>

<a class="link" href={itemData.url}>
  <div class="el">
    <div class="thmb" style="background-image: url({itemData.thumbnailUrl})" />

    <div class="checkout-item-column">
      <div>
        <div>{itemData.name}</div>
        {#if itemData.amount}
          <div>
            <span class="item-label">{$S_translations.amount + ': '}</span>
            <span class="item-label">{itemData.amount}</span>
          </div>
        {/if}
      </div>
      <div><Price price={itemData.price} /></div>
    </div>
  </div></a
>

<style>
  .link {
    border: 1px solid transparent;
    cursor: pointer;
    width: 100%;
  }
  .el {
    --border-subtle: 1px solid #f7f7f7;
    --border-strong: 1px solid #555;
    --border-hidden: 1px solid transparent;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: space-between;
  }

  .item-label {
    font-size: 0.8rem;
  }

  .link:hover {
    border: 1px solid var(--accent);
  }

  .thmb {
    width: 80px;
    height: 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }
  .checkout-item-column {
    width: 100%;
    padding: 0em 0.5em;
    display: flex;
    flex-direction: column;
    height: 80px;
    justify-content: space-between;
  }
</style>

import type { ICartItem } from '../models/cart-item.model';
import { getStateVals, S_items } from '../store/store';
import { IonConfig, setupAppFromConfig } from '../utils/config.service';
import {
  ATTR_CONFIG,
  ATTR_ITEM_SPEC,
  ID_ADD,
  ID_CART_HOST,
  ID_CHECKOUT_HOST,
} from '../utils/selectors';
import { getCartExport } from '../utils/utils';
import AddToCart from './AddToCart.svelte';
import App from './App.svelte';
import Checkout from './Checkout.svelte';

let app, checkoutApp;

declare global {
  interface Window {
    ionCart: any;
  }
}
window.ionCart = window.ionCart || {};

const hostEl = document.body.querySelector(ID_CART_HOST) as HTMLElement;
const checkoutHost = document.body.querySelector(ID_CHECKOUT_HOST);
const addToCartBtnHosts = document.body.querySelectorAll(ID_ADD);
const config: IonConfig = JSON.parse(hostEl.getAttribute(ATTR_CONFIG));

setupAppFromConfig(config).then(({ apiService }) => {
  window.ionCart.get = async () => {
    const cart = await apiService.getCart();
    const { items, currency, purchaseCountry, locale } = cart;
    const publicCart = getCartExport(items, currency, purchaseCountry, locale);
    return publicCart;
  };

  S_items.subscribe((items) => apiService.setCart(getStateVals()));

  window.postMessage('ionCartReady', '/');
});

if (hostEl) {
  app = new App({
    target: hostEl,
    props: {
      hostEl,
      config,
    },
  });
}
if (checkoutHost) {
  checkoutApp = new Checkout({
    target: checkoutHost,
  });
}

const btns = [];
if (addToCartBtnHosts.length) {
  for (let i = 0; i < addToCartBtnHosts.length; i++) {
    const target = addToCartBtnHosts[i];
    const cartItem: ICartItem = JSON.parse(target.getAttribute(ATTR_ITEM_SPEC));
    btns.push(
      new AddToCart({
        target,
        props: { cartItem },
      })
    );
  }
}

export default { app, checkoutApp };

import type { IState } from '../store/store';
export class ApiService {
    constructor(private apiUrl: string) {}


    private async get(url): Promise<any> {
        try {
            const r = await fetch(url)
            return r.json();
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
    private async post(url, body): Promise<any> {
        try {
            return await fetch(url, { method: 'POST', body });
        } catch (err) {
            console.log(err);
            throw err;
        }
    }


    public async setCart(state: IState): Promise<IState> {
        return this.post(this.apiUrl, JSON.stringify(state));
    }

    public async getCart(): Promise<IState> {
        const state = await this.get(this.apiUrl) as unknown as IState;
        if(state) {
            return state;
        }
    }

}

import { ApiService } from '../api/api.service';
import * as defaultTr from '../locales/translation.json';
import {
  S_currencies,
  S_currency,
  S_items,
  S_locale,
  S_locales,
  S_purchaseCountries,
  S_purchaseCountry,
  S_translations,
} from '../store/store';

export interface IColors {
  primary?: string;
  primaryPair?: string;
  accent?: string;
  contrast?: string;
}
export interface IonConfig {
  colors?: IColors;
  translations?: string;
  checkoutPage?: string;
  apiUrl?: string;
  infoMessage?: string;
  iconBlacklistedUrls?: string[];
  purchaseCurrencies?: string[];
  purchaseCountries?: string[];
  locales?: string[];
}

interface IApp {
  apiService: ApiService;
}

export const setupAppFromConfig = async (config: IonConfig): Promise<IApp> => {
  const apiService = new ApiService(config.apiUrl);
  const { items, currency, locale, purchaseCountry } =
    await apiService.getCart();

  const {
    translations,
    purchaseCurrencies,
    locales,
    purchaseCountries,
    colors,
  } = config;
  const defaultTranslations = defaultTr;
  const translationData = {
    ...defaultTranslations,
    ...(translations ? JSON.parse(translations) : {}),
  };

  S_translations.set(translationData);
  S_currencies.set(purchaseCurrencies || config.purchaseCurrencies);
  S_currency.set(currency || config.purchaseCurrencies[0]);
  S_purchaseCountries.set(purchaseCountries || config.purchaseCountries);
  S_purchaseCountry.set(purchaseCountry || config.purchaseCountries[0]);
  S_locales.set(locales || config.locales);
  S_locale.set(locale || config.locales[0]);

  if (items) {
    S_items.set(items);
  }

  return { apiService };
};

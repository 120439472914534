<script lang="ts">
  import {
    S_currencies,
    S_currency,
    S_isExpanded,
    S_totalCount,
    S_translations,
  } from '../store/store';
  import Btn from './Btn.svelte';
  const setVal = (e: FocusEvent) =>
    S_currency.set((e.target as HTMLSelectElement).value);
</script>

<div class="cart-topbar">
  <div>
    {#if $S_currencies.length > 1}
      <div class="label-wrapper">{$S_translations.currency}</div>
      <select name="currencies" on:blur={setVal}>
        {#each $S_currencies as currency}
          <option selected={$S_currency === currency} value={currency}
            >{currency}</option
          >
        {/each}
      </select>
    {/if}
  </div>
  <div class="title">{$S_translations.cart} ({$S_totalCount})</div>
  <Btn click={() => S_isExpanded.set(false)} icon={'clear'} />
</div>

<style>
  .label-wrapper {
    font-size: 0.7em;
    height: 1.2em;
    display: flex;
    align-items: center;
  }
  select {
    height: 2em;
    padding: 0 1em 0 1em;
    border-color: #bfbfbf;
    color: #bfbfbf;
  }
  .cart-topbar {
    width: 100%;
    display: flex;
    padding: 1em 1em;
    align-items: flex-start;
    justify-content: space-between;
  }
  .title {
    min-height: 2em;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    border-bottom: var(--border);
  }
</style>

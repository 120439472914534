<script lang="ts">
  import { v4 as uuidv4 } from 'uuid';
  import type { ICartItem } from '../models/cart-item.model';
  import type { IVariationGroup } from '../models/variation-group.model';
  import type { IVariation } from '../models/variation.model';
  import { S_items, S_translations } from '../store/store';
  import { getDefaultVariationsGroups } from '../utils/utils';
  import Btn from './Btn.svelte';
  import Price from './Price.svelte';
  const add = () => {
    if (!notSelectedVariationGroups.length) {
      S_items.add({ ...cartItem, price });
      selectedVariations = {};
      addingToCart = false;
      validateVariations();
    }
  };

  const validateVariations = (): void => {
    notSelectedVariationGroups = [];
    // Update arr with not selected items
    cartItem.variationGroups.forEach((x) => {
      if (!selectedVariations[x._tmpId]) {
        notSelectedVariationGroups.push(x);
      }
    });
  };

  const updateItemVariationsAndAdditionalPrice = (): void => {
    additionalPrice = 0;

    Object.keys(selectedVariations).forEach((key) => {
      const variation = selectedVariations[key];
      if (variation.additionalFee) {
        additionalPrice += Number(variation.additionalFee);
      }
    });

    cartItem.variations = Object.keys(selectedVariations)
      .map((key) => selectedVariations[key].value)
      .toString();
  };

  const variationClicked = (variation: IVariation, groupId: string): void => {
    if (selectedVariations[groupId] === variation) {
      selectedVariations[groupId] = undefined;
      delete selectedVariations[groupId];
    } else {
      selectedVariations[groupId] = variation;
    }
    validateVariations();
    updateItemVariationsAndAdditionalPrice();
  };

  const getValidationErr = (): string => {
    let err = $S_translations.errorSelectVariation;
    for (let i = 0; i < notSelectedVariationGroups.length; i++) {
      const notSelectedVariation = notSelectedVariationGroups[i];
      err +=
        ' ' +
        notSelectedVariation.name.toLowerCase() +
        (i < notSelectedVariationGroups.length - 1 ? ' & ' : '');
    }
    return err;
  };
  const tmp = document.body.querySelector('#BACKUPMARKUP');
  if (tmp) {
    tmp.remove();
  }

  export let cartItem: ICartItem;

  if (!cartItem.variationGroups) {
    cartItem.variationGroups = getDefaultVariationsGroups();
  }

  // Generate tmp ids to enable us to map to these later
  cartItem.variationGroups.forEach((x) => (x._tmpId = uuidv4()));

  let addingToCart = false;
  let selectedVariations: { [key: string]: IVariation } = {};
  let notSelectedVariationGroups: IVariationGroup[] = [];
  let additionalPrice = 0;
  $: price = cartItem.price + additionalPrice;

  // Select first item
  const firstGroup = cartItem.variationGroups[0];
  const firstItem = firstGroup.variations[0];

  variationClicked(firstItem, firstGroup._tmpId);
</script>

<div class="add-to-cart">
  {#each cartItem.variationGroups as variationGroup}
    <div class="ion-select-variation">
      {$S_translations.select}
      {variationGroup.name.toLowerCase()}
    </div>
    <div class="variations-selector">
      {#each variationGroup.variations as variation}
        <div class="add-to-cart-btn-wrapper">
          <Btn
            lineThrough={!cartItem.inStock}
            disabled={!cartItem.inStock}
            active={addingToCart ||
              selectedVariations[variationGroup._tmpId] === variation}
            text={variation.name}
            title={variationGroup.name.toLowerCase() + ': ' + variation.value}
            click={() => variationClicked(variation, variationGroup._tmpId)}
            activeColor={'var(--ionAccent)'}
          />
        </div>
      {/each}
    </div>
  {/each}
  <div style="width: 100%;">
    {#if cartItem.inStock}
      {#if cartItem.discount}
        <div
          style="position: absolute; margin-top: -1.2rem; text-decoration: line-through;"
        >
          <Price price={price + cartItem.discount} />
        </div>
        <div style="color: #ff6565" class="price">
          <Price {price} />
        </div>
      {:else}
        <div class="price"><Price {price} /></div>
      {/if}
    {/if}

    <Btn
      lineThrough={!cartItem.inStock}
      disabled={!cartItem.inStock ||
        addingToCart ||
        !!notSelectedVariationGroups.length}
      text={addingToCart
        ? $S_translations.addedToCart
        : $S_translations.addToCart}
      click={() => add()}
      title={notSelectedVariationGroups.length ? getValidationErr() : ''}
      width={'12em'}
      primaryColor={'var(--ionAccent)'}
    />

    {#if !cartItem.inStock}
      <div class="outofstock-info">
        {$S_translations.outOfStock}
      </div>
    {/if}
  </div>
</div>

<style>
  .outofstock-info {
    margin-top: 1em;
    background: #ff7272;
    padding: 0.5em;
    color: white;
    display: inline-block;
    text-transform: uppercase;
  }
  .price {
    font-size: 1.2em;
    margin: 1em 0;
  }
  .variations-selector {
    margin-top: 0.5em;
    display: flex;
    flex-wrap: wrap;
  }

  .add-to-cart-btn-wrapper {
    margin: 0em 0.5em 0.5em 0;
  }
  .add-to-cart-btn-wrapper:last-of-type {
    margin-right: 0;
  }
</style>

<script lang="ts">
  import svg from '../public/assets/icons/symbol-defs.svg';
  import type { IonConfig } from '../utils/config.service';
  import { S_isExpanded, S_totalCount } from './../store/store';
  import Cart from './Cart.svelte';
  import Icon from './Icon.svelte';
  import IconCount from './IconCount.svelte';
  export let hostEl: HTMLElement;
  export let config: IonConfig;

  const isExcludedUrl = !!config.iconBlacklistedUrls.find(
    (excludedUrl) => window.location.href.indexOf(excludedUrl) !== -1
  );
  const { infoMessage } = config;

  // Hide icon host if on excluded url (for eg. checkout page)
  hostEl.style.display = isExcludedUrl ? 'none' : '';

  hostEl.addEventListener('click', () => S_isExpanded.toggle());
</script>

{@html svg}
<!-- Stop propagation in order to do clicks on children that doesn't toggle -->
<div on:click={(e) => e.stopPropagation()}>
  <Cart checkoutPage={config.checkoutPage} {infoMessage} />
</div>
<Icon color={'var(--ionContrast)'} icon={'local_grocery_store'} />

{#if $S_totalCount}
  <IconCount />
{/if}

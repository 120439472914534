<script lang="ts">
  import { S_items } from '../store/store';
  import CartSummary from './CartSummary.svelte';
  import CheckoutItem from './CheckoutItem.svelte';
</script>

{#if $S_items.length}
  <div class="checkout">
    <div class="checkout-inner">
      <!-- <div class="title">{$S_translations.checkout}</div> -->
      <div class="checkout-cart-items">
        {#each $S_items as itemData}
          <CheckoutItem {itemData} />
        {/each}
      </div>
    </div>
    <CartSummary />
    <div class="btn-wrapper" />
  </div>
{:else}
  There are no items in your cart.
{/if}

<style>
  .checkout {
    max-width: 720px;
    margin: 0 auto;
  }
  .checkout-inner {
    min-height: calc(100vh - 48px - 64px - 8em);
    background-color: #f7f7f7;
    padding: 0.5em;
  }
  .btn-wrapper {
    margin: 2em 0;
    display: flex;
    width: 100%;
  }

  .checkout-cart-items {
    display: flex;
    flex-direction: column;
  }
</style>

<script lang="ts">
  import { S_totalPrice, S_totalVAT, S_translations } from '../store/store';
  import Price from './Price.svelte';
</script>

<div class="summary-wrapper">
  <div class="summary-item">
    <!-- Amount of items: {$totalCount} -->
  </div>
  <div class="summary-item">
    {$S_translations.totalPrice}: <Price
      price={$S_totalPrice}
      VAT={$S_totalVAT}
    />
  </div>
</div>

<style>
  .summary-wrapper {
    text-transform: none;
    width: 100%;
  }
  .summary-item {
    padding: 0.5em;
  }
  .summary-item:not(:last-of-type) {
    padding-bottom: 0;
  }
</style>

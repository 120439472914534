<script lang="ts">
  import { tweened } from 'svelte/motion';
  import { quadInOut } from 'svelte/easing';
  import { S_translations } from '../store/store';
  import { round } from '../utils/utils';

  export let bold = true;
  export let price;
  export let VAT = 0;
  const currency = 'SEK'; // TODO, do dynamically

  let priceChangeCount = 0;

  $: outputPrice = round(price);
  $: outputVAT = round(VAT);

  const lapsedOpacity = tweened(1, {
    duration: 400,
    easing: quadInOut,
  });

  const minOpacity = 0.5;
  $: {
    price;
    if (priceChangeCount) {
      lapsedOpacity.set(minOpacity);
    }
    priceChangeCount++;
  }

  $: {
    const val = $lapsedOpacity;
    if (val === minOpacity) {
      lapsedOpacity.set(1);
    }
  }
</script>

<span
  class="ion-price"
  style="font-weight: {bold
    ? 600
    : ''}; opacity: {$lapsedOpacity}; {$lapsedOpacity < 0.75
    ? 'color: #ee8888'
    : ''}"
>
  {outputPrice.toFixed()}
  {currency}
</span>
<span class="ion-price" style="font-size: .8em">
  {VAT
    ? '(' +
      $S_translations.includes +
      ' ' +
      outputVAT +
      ' ' +
      currency +
      ' ' +
      $S_translations.VAT +
      ')'
    : ''}
</span>

<style>
  .ion-price {
    transition: color 0.2s ease-in-out;
  }
</style>

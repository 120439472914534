import { get_store_value } from 'svelte/internal';
import type { ICartItem } from '../models/cart-item.model';
import type { IExposedCart } from '../models/exposedCart.model';
import type { IOrderline } from '../models/orderline.model';

export function toArr(arr: NodeList): HTMLElement[] {
  return Array.prototype.slice.call(arr);
}
export function attr(el: HTMLElement, attributeName: string): string {
  return el.getAttribute(attributeName);
}

export const getItemsTotalCount = (items) =>
  sumArrProp(get_store_value(items))('amount');
export const totalPriceReducer = (arr: ICartItem[]) =>
  arr.reduce((a, b) => {
    const prevPrice = a;
    const addPrice = (b.price || 0) * b.amount;
    return prevPrice + addPrice;
  }, 0);
export const totalVATReducer = (arr: ICartItem[]) =>
  arr.reduce((acc, curr) => {
    const prevVAT = acc;
    const price = (curr.price || 0) * curr.amount;
    const priceWithoutVAT = getVATFromPrice(price, curr.VAT);
    const VAT = price - priceWithoutVAT;
    return prevVAT + VAT;
  }, 0);

// Vat in percent, as 25 for 25%
export const getVATFromPrice = (price: number, VAT: number) => {
  return price / (1 + VAT / 100);
};

export const sumArrProp = (arr: { [key: string]: number }[]) => (key: string) =>
  arr.reduce((a, b) => a + (b[key] || 0), 0);

export function round(num: number) {
  const m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
}

export const deleteItem = (arr: ICartItem[], i: number) => {
  arr.splice(i, 1);
  return arr;
};

export const incrementItem = (item: ICartItem, add: number) => {
  const newAmount = item.amount + add;
  if (newAmount <= item.maxAmount) {
    item.amount = newAmount;
  }
  return item;
};

export const getDefaultVariationsGroups = () => {
  return [
    {
      name: 'Color',
      variations: [
        {
          name: 'Red',
          value: '#ff0000',
        },
        {
          name: 'Green',
          value: '#00ff00',
          additionalFee: 200,
        },
        {
          name: 'Blue',
          value: '#0000ff',
        },
      ],
    },
  ];
};

export const getCartExport = (
  items: ICartItem[],
  purchaseCurrency: string,
  purchaseCountry: string,
  locale: string
): IExposedCart => {
  if (!items) {
    return;
  }
  const orderLines: IOrderline[] = items.map(
    ({
      type,
      id,
      name,
      amount,
      variations,
      quantityUnit,
      price,
      VAT,
      // discount,
    }) => {
      const totalAmount = price * amount;
      // const totalDiscountAmount = discount * amount;
      return {
        type,
        reference: id,
        name: `${name} - ${variations.replace(',', ', ')}`,
        quantity: amount,
        quantityUnit,
        unitPrice: price,
        taxRate: VAT,
        totalAmount,
        totalDiscountAmount: 0,
        totalTaxAmount: totalAmount - getVATFromPrice(totalAmount, VAT),
      };
    }
  );

  return {
    purchaseCurrency,
    purchaseCountry,
    locale,
    orderAmount: orderLines.reduce((acc, curr) => acc + curr.totalAmount, 0),
    orderTaxAmount: orderLines.reduce(
      (acc, curr) => acc + curr.totalTaxAmount,
      0
    ),
    orderLines: orderLines,
  };
};

<script lang="ts">
  import { S_totalCount, S_translations } from '../store/store';
  import Btn from './Btn.svelte';
  import CartSummary from './CartSummary.svelte';

  export let checkoutPage;
</script>

<div class="cart-footer">
  <CartSummary />
  <Btn
    disabled={!$S_totalCount}
    text={$S_translations.checkout}
    click={() => (window.location.href = checkoutPage)}
  />
</div>

<style>
  .cart-footer {
    padding: 1em;
    background: #f7f7f7;
  }
</style>

<script lang="ts">
  import { S_totalCount } from '../store/store';
</script>

<div class="icon-count">{$S_totalCount}</div>

<style>
  .icon-count {
    background: var(--ionAccent);
    width: 2em;
    height: 1.35em;
    border-radius: 2em;
    color: #333;
    position: absolute;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0.25em;
    margin-left: 1.5em;
    box-sizing: border-box;
  }
</style>

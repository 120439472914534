import { get_store_value } from 'svelte/internal';
import { derived, writable } from 'svelte/store';
import type { ICartItem } from '../models/cart-item.model';
import type { ITranslation } from '../models/translation.model';
import {
  deleteItem,
  incrementItem,
  sumArrProp,
  totalPriceReducer,
  totalVATReducer,
} from '../utils/utils';

//=============================================================================
// STORES SAVED IN LOCALSTORAGE
//=============================================================================

export const S_items = (function () {
  const match = (x, id, variations) =>
    x.id === id && x.variations === variations;
  const { subscribe, set, update } = writable([]);
  return {
    subscribe,
    add: (newItem: ICartItem) =>
      update((items: ICartItem[]) => {
        const i = items.findIndex((x) =>
          match(x, newItem.id, newItem.variations)
        );
        const exists = i !== -1;
        if (exists) {
          const oldItem = items[i];
          incrementItem(oldItem, newItem.amount);
          return items;
        } else {
          return (items = [...items, newItem]);
        }
      }),
    dec: (id: string, variations: string) =>
      update((items: ICartItem[]) => {
        const item = items.find((x) => match(x, id, variations));
        if (item.amount > 0) {
          item.amount--;
        }
        return items;
      }),
    inc: (id: string, variations: string) =>
      update((items: ICartItem[]) => {
        const item = items.find((x) => match(x, id, variations));
        incrementItem(item, 1);
        return items;
      }),
    delete: (id: string, variations: string) =>
      update((items: ICartItem[]) => {
        const i = items.findIndex((x) => match(x, id, variations));
        return (items = [...deleteItem(items, i)]);
      }),
    set: (val: ICartItem[]) => set(val),
    reset: () => set([]),
  };
})();

export const S_currencies = (function () {
  const { subscribe, set } = writable([]);
  return {
    subscribe,
    set: (val: string[]) => set(val),
    reset: () => set([]),
  };
})();

export const S_currency = (function () {
  const { subscribe, set } = writable('');
  return {
    subscribe,
    set: (val: string) => set(val),
    reset: () => set(''),
  };
})();

export const S_purchaseCountries = (function () {
  const { subscribe, set } = writable([]);
  return {
    subscribe,
    set: (val: string[]) => set(val),
    reset: () => set([]),
  };
})();

export const S_purchaseCountry = (function () {
  const { subscribe, set } = writable('');
  return {
    subscribe,
    set: (val: string) => set(val),
    reset: () => set(''),
  };
})();

export const S_locales = (function () {
  const { subscribe, set } = writable([]);
  return {
    subscribe,
    set: (val: string[]) => set(val),
    reset: () => set([]),
  };
})();

export const S_locale = (function () {
  const { subscribe, set } = writable('');
  return {
    subscribe,
    set: (val: string) => set(val),
    reset: () => set(''),
  };
})();

//=============================================================================
// SYNC WITH LOCALSTORAGE - (CONSIDER DOING THIS ELSEWHERE)
//=============================================================================
export interface IState {
  items: ICartItem[];
  currency: string;
  locale: string;
  purchaseCountry: string;
}

export const getStates = () => {
  const syncedState = {
    items: S_items,
    currency: S_currency,
    locale: S_locale,
    purchaseCountry: S_purchaseCountry,
  };
  return syncedState;
};

export const getStateVals = () => {
  const syncedState: IState = {
    items: get_store_value(S_items),
    currency: get_store_value(S_currency),
    locale: get_store_value(S_locale),
    purchaseCountry: get_store_value(S_purchaseCountry),
  };
  return syncedState;
};

//=============================================================================
// STORES ONLY SAVED IN MEMORY
//=============================================================================

export const S_totalCount = derived(S_items, ($items) => {
  if ($items?.length) {
    return sumArrProp($items)('amount');
  } else {
    return 0;
  }
});

export const S_totalPrice = derived(S_items, ($items) =>
  totalPriceReducer($items)
);

export const S_totalVAT = derived(S_items, ($items) => totalVATReducer($items));

export const S_isExpanded = (function () {
  const { subscribe, set, update } = writable(false);
  return {
    subscribe,
    toggle: () => update((isExpanded) => (isExpanded = !isExpanded)),
    set: (val: boolean) => set(val),
  };
})();

export const S_translations = (function () {
  const { subscribe, set } = writable({} as ITranslation);
  return { subscribe, set: (val: ITranslation) => set(val) };
})();

<script lang="ts">
  import { cubicInOut } from 'svelte/easing';
  import { tweened } from 'svelte/motion';

  export let msg = '';
  export let doToast = false;

  const width = 160;
  const margin = 10;
  const lapseRate = tweened(0, {
    duration: 500,
    easing: cubicInOut,
  });

  $: doToast ? lapseRate.set(1) : lapseRate.set(0);
</script>

<div
  class="el toast"
  style={'right: ' + (-width + margin + width * $lapseRate) + 'px'}
>
  {msg}
</div>

<style>
  .el {
    width: 140px;
    height: 70px;
    font-size: 14px;
    margin-top: 50px;
    position: fixed;
    display: flex;
    padding: 0 1em;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 0 0 0.75em rgb(194, 194, 194);
    top: 1em;
    right: -160px;
    z-index: 1000000;
    transition: margin-top 0.5s ease-in-out;
  }
</style>

<script lang="ts">
  import type { ICartItem } from '../models/cart-item.model';
  import { S_items } from '../store/store';
  import Btn from './Btn.svelte';
  import Price from './Price.svelte';

  export let item: ICartItem;
</script>

<div class="ion-cart-item-wrapper">
  <div class="thmb" style="background-image: url({item.thumbnailUrl})" />

  <div class="item-column" style="width: 100%">
    <a href={item.url}
      ><div class="item-name">
        {item.name}
        <br />
        <span style="font-size: .8em"
          >{item.variations.replace(',', ', ')}
        </span>
      </div></a
    >
    <Price bold={false} price={item.price * item.amount} />
  </div>

  <div class="flex">
    {#if item.amount <= 1}
      <Btn
        click={() => S_items.delete(item.id, item.variations)}
        icon={'delete_outline'}
      />
    {:else}
      <Btn click={() => S_items.dec(item.id, item.variations)} icon={'minus'} />
    {/if}

    <div class="counter">{item.amount}</div>
    <Btn click={() => S_items.inc(item.id, item.variations)} icon={'add'} />
  </div>
</div>

<style>
  .ion-cart-item-wrapper {
    --border-subtle: 1px solid #f7f7f7;
    --border-strong: 1px solid #555;
    --border-hidden: 1px solid transparent;
    width: 100%;
    height: 80px;
    display: flex;
    padding: 0 0.5em 0 0em;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
  }
  .ion-cart-item-wrapper:hover {
    border: var(--border-subtle);
  }
  .item-name {
    border-bottom: var(--border-hidden);
  }
  .item-name:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .flex {
    display: flex;
  }

  .thmb {
    width: 60px;
    height: 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }
  .counter {
    --border: 1px solid #ccc;
    min-width: 2em;
    min-height: 2em;
    padding: 0 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: var(--border);
    border-bottom: var(--border);
  }
  .item-column {
    display: block;
    margin: 0 1em;
  }
</style>

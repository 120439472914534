<script lang="ts">
  import Icon from './Icon.svelte';
  export let lineThrough = false;
  export let text = '';
  export let icon = '';
  export let title = '';
  export let active = false;
  export let click: (e?: MouseEvent) => any;
  export let disabled = false;
  export let width = '';
  export let primaryColor = 'var(--ionPrimary)';
  export let contrastColor = 'var(--ionContrast)';
  export let activeColor = 'var(--ionPrimaryPair)';
  $: style =
    'background-color: ' +
    (active ? activeColor : primaryColor) +
    '; ' +
    (width ? 'width: ' + width : !text ? 'width: 2em' : '');

  const onClick = (e) => (!disabled ? click(e) : '');
</script>

{#if icon}
  <div
    {title}
    class={'ioncart-btn ' +
      (disabled ? 'disabled' : '') +
      ' ' +
      (lineThrough ? 'line-through' : '')}
    {style}
    on:click={onClick}
  >
    <Icon color={contrastColor} {icon} />
    <div>{text}</div>
  </div>
{:else}
  <div
    {title}
    class={'ioncart-btn ' +
      (disabled ? 'disabled' : '') +
      ' ' +
      (lineThrough ? 'line-through' : '')}
    {style}
    on:click={onClick}
  >
    <div>{text}</div>
  </div>
{/if}

<style>
  :root {
    --border-subtle: 1px solid rgb(255, 255, 255);
    --border-strong: 1px solid rgb(107, 107, 107);
  }
  .ioncart-btn {
    min-width: 2.5em;
    height: 2.5em;
    display: flex;
    flex-direction: column;
    padding: 0 0.5em;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border: var(--border-strong);

    cursor: pointer;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  }
  .ioncart-btn > div {
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  .ioncart-btn:hover:not(.disabled):not(.active) {
    border: var(--border-strong);
  }
  .disabled {
    background: #f7f7f7 !important;
    color: #ccc !important;
    cursor: default;
    border: var(--border-subtle);
  }

  .line-through {
    text-decoration: line-through;
  }
</style>

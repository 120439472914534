<script lang="ts">
  import { S_isExpanded, S_items, S_translations } from '../store/store';
  import CartFooter from './CartFooter.svelte';
  import CartItem from './CartItem.svelte';
  import CartTopbar from './CartTopbar.svelte';
  import ToastMsg from './ToastMsg.svelte';

  export let checkoutPage;
  export let infoMessage;

  let showToast = false;
  let i = 0;

  S_items.subscribe(() => {
    const isFirstIteration = i <= 1;
    i++;

    if (isFirstIteration) {
      return;
    }

    if (!$S_isExpanded) {
      showToast = true;
    }
    setTimeout(() => (showToast = false), 2500);
  });
</script>

<div class="cart-wrapper" style={$S_isExpanded ? 'right: 0;' : ''}>
  <ToastMsg msg={$S_translations.addedToCart} doToast={showToast} />
  <div class="cart">
    <CartTopbar />

    {#if infoMessage}
      <div
        class="info-message"
        contenteditable="false"
        bind:innerHTML={infoMessage}
      />
    {/if}

    <div class="cart-items">
      {#each $S_items as item}
        <div class="cart-item">
          <CartItem {item} />
        </div>
      {/each}
    </div>

    <CartFooter {checkoutPage} />
  </div>
</div>
<div
  on:click={() => S_isExpanded.set(false)}
  class="dimmer"
  style={$S_isExpanded ? 'display: block;' : ''}
/>

<style>
  .info-message {
    font-size: 9px;
    padding: 2em;
    line-height: 1.5em;
    background: #f7f7f7;
  }
  .cart-wrapper {
    overflow: hidden;
    color: black;
    position: absolute;
    top: 0;
    right: -25em;
    width: 25em;
    height: 100vh;
    background-color: #fff;
    z-index: 1000;
  }
  .cart {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .cart-items {
    height: 100%;
    overflow: auto;
  }
  .cart-item {
    padding: 0.5rem;
  }

  .dimmer {
    width: 100%;
    height: 100vh;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    left: 0;
    top: 0;
    z-index: 999;
  }
</style>

<script lang="ts">
  export let icon: string;
  export let width = '16px';
  export let height = '16px';
  export let color = 'black;';
  $: colorMarkup = 'fill:' + color + ';';
</script>

<svg style={colorMarkup} {width} {height}><use xlink:href="#icon-{icon}" /></svg
>
